import parse from 'html-react-parser'
import Link from 'next/link'
import { FC } from 'react'

import { EMPTY_LINK } from '@constants_folder/common'
import {
  COUNTRIES_WITHOUT_LEGAL_ADDRESS,
  EU_COUNTRIES,
  LEGAL_ENTRY_REGION,
  TEMPORARY_UAE_LEGAL_COUNTRIES,
  UAE_LEGAL_COUNTRIES,
  US_LEGAL_COUNTRIES,
} from '@constants_folder/countries'

import { FooterTypes } from '@_types/landings'

import { useQueryCountry } from '@utils/useQueryCountry'

import { NavigationType } from '@templates/LandingNavigation/types'

import ButtonsList from '@modules/Footer/ButtonsList/ButtonsList'
import LinksColumns from '@modules/Footer/LinksColumns/LinksColumns'

import styles from './footer.module.scss'

const year = new Date().getFullYear()

type Props = {
  type?: NavigationType
  footerData: FooterTypes
}

const Footer: FC<Props> = ({ footerData, type = 'default' }) => {
  const { countryCode, region } = useQueryCountry()

  const isCalifornia = region === 'California'
  const isShowLegalAddress =
    countryCode && !COUNTRIES_WITHOUT_LEGAL_ADDRESS.includes(countryCode)

  const isUAELegal = UAE_LEGAL_COUNTRIES.includes(countryCode)

  const isTemporaryUAE = TEMPORARY_UAE_LEGAL_COUNTRIES.includes(countryCode)

  const isUsLegal =
    US_LEGAL_COUNTRIES.includes(countryCode) &&
    (LEGAL_ENTRY_REGION.length ? LEGAL_ENTRY_REGION.includes(region) : true)
  const isUaLegal = countryCode === 'UA'
  const useCookiePolicyCountries = [...EU_COUNTRIES, 'GB', 'US']
  const isShowCookiePolicy = useCookiePolicyCountries.includes(countryCode)

  const handleLegalAddress = (): string => {
    if (!footerData || isCalifornia) return ''

    const { UAEAddress, USAddress, nonUAEAddress } = footerData

    if (isUaLegal) {
      // return UAAddress
      return ''
    }

    if (isUAELegal || isTemporaryUAE) {
      return UAEAddress
    }

    if (isUsLegal) {
      return USAddress
    }

    return nonUAEAddress
  }

  if (!footerData) return null

  return (
    <footer className={styles.footer}>
      {type === 'default' && (
        <>
          <div className={styles.footer_top}>
            <LinksColumns
              customClassNames={{
                title: styles.title,
                column: styles.column,
                item: styles.main_link_item,
              }}
              linksList={footerData.mainLinks}
              cookieSettings={{
                title: footerData.privacySettingsTitle,
                column: 1,
              }}
            >
              <ButtonsList buttonsList={footerData.downloadLinks} />
            </LinksColumns>
          </div>
          <div className={styles.footer_center}>
            <LinksColumns linksList={footerData.otherLinks} />
          </div>
        </>
      )}

      <div className={styles.footer_bottom}>
        ©{year} Promova
        <span className={styles.links_box}>
          <Link href={footerData.termsLinkUrl || EMPTY_LINK}>
            <a className={styles.nav_link}>{footerData.termsLinkTitle}</a>
          </Link>{' '}
          |{' '}
          <Link href={footerData.privacyLinkUrl || EMPTY_LINK}>
            <a className={styles.nav_link}>{footerData.privacyLinkTitle}</a>
          </Link>
          {isShowCookiePolicy && (
            <>
              {' | '}
              <Link href={footerData.cookieLinkUrl || EMPTY_LINK}>
                <a className={styles.nav_link}>{footerData.cookieLinkTitle}</a>
              </Link>
            </>
          )}
        </span>
        {isShowLegalAddress && parse(handleLegalAddress())}
      </div>
    </footer>
  )
}
export default Footer
