import {
  CTA_GEO_TIMER_WITH_BUTTON,
  DEFAULT_LOCALE,
  StrapiCtaBannerList,
} from '@constants'

import {
  CtaGeoTimerWithButtonStrapiRes,
  CtaTimerWithButtonAllPagesType,
} from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import { CTA_REFETCH_ATTEMPTS, HOURS_24 } from '@hooks/dataFetching/constants'
import { useQueryFactory } from '@hooks/dataFetching/useQueryFactory'
import { fetchStrapiViaProxy } from '@hooks/dataFetching/utils'

import { useQueryCountry } from '@utils/useQueryCountry'

const CTA_TIMER_WITH_BUTTON_POPULATE = ['timer.timerBgColor', 'button.icon']

interface Props {
  locale?: string | undefined
  enabled?: boolean
}

export const useGeoCtaTimerWithButtonQuery = ({
  locale = DEFAULT_LOCALE,
  enabled = true,
}: Props) => {
  const { countryCode } = useQueryCountry()

  const geoRes = useQueryFactory(
    [CTA_GEO_TIMER_WITH_BUTTON, locale],
    (): Promise<CtaGeoTimerWithButtonStrapiRes> =>
      fetchStrapiViaProxy({
        url: CTA_GEO_TIMER_WITH_BUTTON,
        urlParamsObject: {
          populate: CTA_TIMER_WITH_BUTTON_POPULATE,
          locale,
          filters: {
            countries: {
              $containsi: countryCode,
            },
          },
        },
      })
  )({
    enabled: enabled && !!countryCode,
    cacheTime: HOURS_24,
    staleTime: HOURS_24,
    retry: CTA_REFETCH_ATTEMPTS,
    select: (data) => data?.data,
  })

  const defaultRes = useCtaBannerListQuery<CtaTimerWithButtonAllPagesType>({
    locale,
    slug: StrapiCtaBannerList.CTA_TIMER_WITH_BUTTON_ALL_PAGES,
    enabled,
  })

  const defaultResData = { attributes: defaultRes.data }

  const data = geoRes.data ? [...geoRes.data, defaultResData] : [defaultResData]

  return {
    data,
    isLoading: geoRes.isLoading || defaultRes.isLoading,
    isError: geoRes.isError || defaultRes.isError,
  }
}
