import { DEFAULT_LOCALE } from '@constants'

import { UrlParamsObject, fetchStrapi } from '@strapi/strapi'

const IS_PROXY = true

const PROXY_PREFIX = '/cms'
interface fetcherParams {
  url: string
  urlParamsObject?: UrlParamsObject
  options?: Record<string, any>
  attempts?: number
}

export const fetchStrapiViaProxy = async ({
  url,
  urlParamsObject,
  options = {},
  attempts = 0,
}: fetcherParams) =>
  fetchStrapi(
    `${PROXY_PREFIX}${url}`,
    {
      ...urlParamsObject,
      locale: urlParamsObject?.locale || DEFAULT_LOCALE,
    },
    options,
    attempts,
    IS_PROXY
  )
